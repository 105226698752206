import * as React from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { ToastContainer, toast } from 'react-toastify'; // then this
import image from "./background.png";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}

            Karma Primary Healthcare Pvt Ltd {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme()


export default function SignInSide() {

    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = React.useState("");
    let navigate = useNavigate();

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^[6-9]\d{9}$/gi;
        return regex.test(phoneNumber);
    }

    const submit = () => async (getState) => {
        console.log("here");
        if (validatePhoneNumber(phoneNumber)) {
            try {
                const url = apiClient.Urls.login;
                const response = await apiClient.post(url, {
                    Mobile: phoneNumber,
                });
                console.log("here");

                if (response["success"]) {
                    toast.success("OTP sent successfully", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    dispatch({ success: true, type: "SEND_OTP", payload: phoneNumber });
                    navigate("/otp");
                } else {
                    toast.info("Please try with a different phone number", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }
            } catch (error) {

            }
        } else {
            toast.error("Please enter a valid phone number", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            });
        }
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            submit();
        }
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={9}
                        style={{ backgroundImage: `url(${image})`, }}
                        sx={{

                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={3} component={Paper} elevation={9} square>
                        <Box
                            sx={{
                                my: 25,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src="https://karmahealthcare.in/assets/img/logo_new.png" alt="" style={{ width: '250px' }} />
                            <Typography component="h1" variant="h6" sx={{ mt: 3, color: '#636578', textAlign: "center" }}>
                                Welcome to Karma Primary Healthcare
                            </Typography>

                            <Typography component="h1" variant="body1" sx={{ mt: 1, color: 'rgba(76, 78, 100, 0.68)' }}>
                                Please enter your phone number
                            </Typography>

                            <Box component="form" noValidate sx={{ mt: 1, color: 'rgba(76, 78, 100, 0.68)' }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Phone number"
                                    autoComplete="off"
                                    autoFocus
                                    value={phoneNumber}
                                    onChange={
                                        (e) => {
                                            setPhoneNumber(e.target.value)
                                            console.log(phoneNumber);
                                        }
                                    }
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    inputProps={
                                        {
                                            inputMode: "numeric",
                                            pattern: "[0-9]{3}[0-9]{2}[0-9]{3}",
                                            maxLength: 10
                                        }
                                    }
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        fontWeight: 500,
                                        backgroundColor: '#26c6f9',
                                    }}
                                    onClick={submit()}
                                >
                                    Send OTP
                                </Button>
                                <Copyright
                                    sx={{
                                        display: 'absolute',
                                        bottom: 5
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <ToastContainer />
        </>

    );
}
