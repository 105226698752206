import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import apiClient from '../../apiClient'
import { ToastContainer, toast } from 'react-toastify';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-datepicker';
import {
    faLocationDot, faExclamationCircle, faArrowRight, fa1, fa2
} from "@fortawesome/free-solid-svg-icons";
const date = new Date();
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

function Example({ args, row }) {
    const [modal1, setModal1] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const toggle1 = () => {
        setModal1(!modal1);
    }
    const toggle2 = () => {
        setModal2(!modal2);
    }
    return (
        <div style={{ width: "80%" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{
                    backgroundColor: '#4a4893',
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: '900',
                    fontSize: '1em',
                    width: "50px",
                    borderRadius: '5%',
                    margin: 'auto',
                    cursor: 'pointer',
                    transition: '0.4s',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "4px 0"
                }}
                    onClick={toggle1}
                >
                    <FontAwesomeIcon icon={fa1} size='2px' />
                </div>
                <div style={{
                    backgroundColor: '#4a4893',
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: '900',
                    fontSize: '1em',
                    width: "50px",
                    borderRadius: '5%',
                    margin: 'auto',
                    cursor: 'pointer',
                    transition: '0.4s',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "4px 0"
                }}
                    onClick={toggle2}
                >
                    <FontAwesomeIcon icon={fa2} size='2px' />
                </div>
            </div>
            <Modal isOpen={modal1 || modal2} toggle={() => {
                if (modal1) {
                    toggle1();
                } if (modal2) {
                    toggle2();
                }
            }} {...args}>
                <ModalHeader toggle={() => {
                    if (modal1) {
                        toggle1();
                    } if (modal2) {
                        toggle2();
                    }
                }}>Image {modal1 ? 1 : 2}</ModalHeader>
                <ModalBody>
                    <img src={row.image_1} style={{ width: "100%" }} />
                </ModalBody>
            </Modal>
        </div>
    );
}

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

export default function CheckIn() {
    const authToken = localStorage.getItem('token');
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment(date).format("MMM Do YY"));
    const [filteredNames, setFilteredNames] = React.useState([]);
    const [filteredNames1, setFilteredNames1] = React.useState([]);

    const [search, setSearch] = useState("");
    const [value, setValue] = useState(new Date());
    const [value1, setValue1] = useState(new Date());
    useEffect(() => {
        const res = filteredNames.filter((data) => {
            return data.name.toLowerCase().match(search.toLowerCase());
        })
        setFilteredNames(res)
    }, [search])

    const forceUpdate = useForceUpdate();

    const LocationCustom = (row) => (
        <div style={{ textAlign: "center" }}>
            {row.latitude && row.longitude ? (
                <a
                    href={`http://maps.google.com/maps?q=${row.latitude},${row.longitude}`}
                    target="_blank"
                >
                    <div style={{
                        color: '#4a4893',
                        textAlign: 'center',
                        fontWeight: '900',
                        fontSize: '1em',
                        width: "50px",
                        borderRadius: '8%',
                        margin: 'auto',
                        cursor: 'pointer',
                        transition: '0.4s',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: "3px 0"
                    }}>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <p style={{
                            margin: '0',
                            padding: '0',
                            paddingLeft: '5px',
                        }}>View</p>
                    </div>
                </a>
            ) : (
                <Button
                    color="primary"
                    variant="contained"
                    style={{ fontSize: "0.6em", backgroundColor: "#e53935" }}
                    startIcon={
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            style={{ fontSize: "10px" }}
                        />
                    }
                ></Button>
            )}
        </div>
    );


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            center: 1
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: false,
            center: 1
        }, {
            name: "Time",
            selector: row => row.time,
            sortable: false,
            center: 1
        },
        {
            name: "Village",
            selector: row => row.village,
            sortable: true,
            center: 1
        },
        {
            name: "Centre",
            selector: row => row.centre,
            sortable: true,
            center: 1
        },
        {
            name: "Division",
            selector: row => row.division,
            sortable: true,
            center: 1
        },
        {
            cell: (row) => <Example row={row} />,
            center: 1,
            name: "Check-in",
        },
        {
            cell: row => <LocationCustom {...row} />,
            center: 1,
            name: "Location",
            selector: row => row.location,
        }
    ]

    const handleClick = (date) => {
        if (selectedDate) {
            apiClient.post(apiClient.Urls.checkIn, {
                authToken: authToken,
                date: date,
            }).then((res) => {
                if (res?.success) {
                    const checkInData = [];
                    const checkOutData = [];
                    res?.data.map((item, index) => {
                        if (item?.type === "checkin") {
                            const dummy = {
                                name: item.user.username,
                                village: item.village.Name,
                                centre: item.chw.Village,
                                division: item.chw.division.Name,
                                image_1: item.attendance_image_1,
                                image_2: item.attendance_image_2,
                                date: moment(item?.attendance_date.slice(0, 10)).format("MMM Do YY"),
                                time: item?.attendance_date.slice(11, 16),
                                latitude: item?.latitude,
                                longitude: item?.longitude,
                                location: item?.latitude + "_" + item.longitude,
                            }
                            checkInData.push(dummy);
                        } else {
                            const dummy = {
                                name: item.user.username,
                                village: item.village.Name,
                                centre: item.chw.Village,
                                division: item.chw.division.Name,
                                image_1: item.attendance_image_1,
                                image_2: item.attendance_image_2,
                                date: moment(item?.attendance_date.slice(0, 10)).format("MMM Do YY"),
                                time: item?.attendance_date.slice(11, 16),
                                latitude: item?.latitude,
                                longitude: item?.longitude,
                                location: item?.latitude + "_" + item.longitude,
                            }
                            checkOutData.push(dummy);
                        }
                    })
                    setFilteredNames(checkInData);
                    setFilteredNames1(checkOutData);
                    forceUpdate()
                } else {
                    toast.error("No records found", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }
            })
        } else {
            toast.error("No records found", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    useEffect(() => {
        apiClient.post(apiClient.Urls.checkIn, {
            authToken: authToken
        }).then(res => {
            if (res.success) {
                const checkInData = [];
                const checkOutData = [];
                if (res.data.length > 0) {
                    toast.success("Data fetched", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    const data = res.data;
                    res?.data.map((item, index) => {
                        if (item?.type === "checkin") {
                            const dummy = {
                                name: item.user.username,
                                village: item.village.Name,
                                centre: item.chw.Village,
                                division: item.chw.division.Name,
                                image_1: item.attendance_image_1,
                                image_2: item.attendance_image_2,
                                date: moment(item?.attendance_date.slice(0, 10)).format("MMM Do YY"),
                                time: item?.attendance_date.slice(11, 16),
                                latitude: item?.latitude,
                                longitude: item?.longitude,
                                location: item?.latitude + "_" + item.longitude,
                            }
                            checkInData.push(dummy);
                        } else {
                            const dummy = {
                                name: item.user.username,
                                village: item.village.Name,
                                centre: item.chw.Village,
                                division: item.chw.division.Name,
                                image_1: item.attendance_image_1,
                                image_2: item.attendance_image_2,
                                date: moment(item?.attendance_date.slice(0, 10)).format("MMM Do YY"),
                                time: item?.attendance_date.slice(11, 16),
                                latitude: item?.latitude,
                                longitude: item?.longitude,
                                location: item?.latitude + "_" + item.longitude,
                            }
                            checkOutData.push(dummy);
                        }
                    })
                    setFilteredNames(checkInData);
                    setFilteredNames1(checkOutData);
                } else {
                    toast.error("No records available", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }
            } else {
                toast.info("No records available", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: true
                })
            }
        }).catch(e => console.log(e))
    }, [])

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize: '1em',
                backgroundColor: '#4a4893',
                color: '#fff',
                padding: '10px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9em',
            },
        },
        table: {
            style: {},
        },
        tableWrapper: {
            style: {
                display: 'table',
            },
        },
        responsiveWrapper: {
            style: {},
        },
        pagination: {
            style: {
                color: '#000',
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: '#fff',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#000',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#000',
                fill: '#000',
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#000',
                    fill: '#000',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#000',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#fff',
                },
            },
        },
    };

    return (
        <div style={{ padding: "0 20px" }}>
            <Breadcrumb style={{ display: "flex", justifyContent: "space-between" }}>
                <BreadcrumbItem active >Check-in records</BreadcrumbItem>
                <BreadcrumbItem active >{selectedDate}</BreadcrumbItem>
                <BreadcrumbItem style={{ display: "flex", flexDirection: "row", width: "25%" }}>
                    <DatePicker selected={value} dateFormat="dd/MM/yyyy" onChange={(date) => {
                        setValue(date)
                        const temp = moment(date.toISOString().split('T')[0]).format("MMMM Do YYYY");
                        setSelectedDate(temp);
                        handleClick(date);
                    }} className="w-100 form-control"
                        maxDate={new Date()}
                    />
                </BreadcrumbItem>
            </Breadcrumb>
            {
                data.length < 0 ? <h1>Loading</h1> : <div className='card'>
                    <DataTable
                        columns={columns}
                        data={filteredNames}
                        pagination
                        dense
                        customStyles={customStyles}
                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                        paginationServer
                        paginationComponentOptions={{ noRowsPerPage: true, showFirstButton: false, showLastButton: false }}
                    />
                </div>
            }
            <Breadcrumb>
                <BreadcrumbItem active >Check-out records</BreadcrumbItem>
                <BreadcrumbItem active > {selectedDate}</BreadcrumbItem>
            </Breadcrumb>
            {
                data.length < 0 ? <h1>Loading</h1> : <div className='card'>
                    <DataTable
                        columns={columns}
                        data={filteredNames1}
                        pagination
                        dense
                        customStyles={customStyles}
                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                        paginationServer
                        paginationComponentOptions={{ noRowsPerPage: true, showFirstButton: false, showLastButton: false }}
                    />
                </div>
            }
            <ToastContainer />
        </div>
    )
}