import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useParams } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import apiClient from "../../apiClient";
import {
    faLocationDot,
    faExclamationCircle,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify"; // then this
import { getDistance } from "geolib";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from 'react-select'
import { Rings } from "react-loader-spinner";
import { useState } from "react";

const date = new Date();

function Example({ args, row }) {
    const [modal1, setModal1] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const toggle1 = () => {
        setModal1(!modal1);
    };
    const toggle2 = () => {
        setModal2(!modal2);
    };
    return (
        <div style={{ width: "80%" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{
                    color: '#4a4893',
                    textAlign: 'center',
                    fontWeight: '900',
                    fontSize: '1em',
                    width: "50px",
                    borderRadius: '8%',
                    margin: 'auto',
                    cursor: 'pointer',
                    transition: '0.4s',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "3px 0"
                }}
                    onClick={toggle1}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                    <p style={{
                        margin: '0',
                        padding: '0',
                        paddingLeft: '5px',
                    }}>View</p>
                </div>
            </div>
            <Modal
                isOpen={modal1 || modal2}
                toggle={() => {
                    if (modal1) {
                        toggle1();
                    }
                    if (modal2) {
                        toggle2();
                    }
                }}
                {...args}
            >
                <ModalHeader
                    toggle={() => {
                        if (modal1) {
                            toggle1();
                        }
                    }}
                >
                    Comment
                </ModalHeader>
                <ModalBody>{row.comment}</ModalBody>
            </Modal>
        </div>
    );
}

const SubHeaderComponent = (props) => {
    const { from,
        to,
        setTo,
        setFrom,
        centres,
        setCentres,
        selectedCentre,
        setSelectedCentre,
        handleClick,
        setFromDate,
        setToDate,
        setDispDate,
        styles,
        conversionStatus,
        setSelectedConversionStatus,
        toDate,
        fromDate,
        dateRange,
        setDateRange,
        startDate,
        endDate,
        selectedConversionStatus
    } = props;


    useEffect(() => {
        console.log(selectedConversionStatus, selectedCentre)

    }, [selectedCentre, selectedConversionStatus])
    return (
        <div className="row" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
            marginBottom: "10px",
        }}>
            <div className="col-md-4">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    isClearable={true}
                    className="w-100 form-control"
                    placeholderText="Select Date Range"
                    customInput={
                        <input
                            type="text"
                            className="form-control"
                            style={{ fontSize: "0.7em", minHeight: "30px", padding: "10px 10px" }}
                        />
                    }
                    maxDate={new Date()}
                    minDate={moment().subtract(3, "months").toDate()}
                />
            </div>
            <div className="col-md-3">
                <Select options={centres} styles={styles} placeholder="Select Centre" className="select" onChange={(choice) => setSelectedCentre(choice.value)} />
            </div>
            <div className="col-md-3">
                <Select options={conversionStatus} styles={styles} placeholder="Conversion" className="select" onChange={(choice) => setSelectedConversionStatus(choice.value)} />
            </div>
            <div className="col-md-2">
                <Button color="primary" onClick={handleClick}>
                    Fetch
                </Button>
            </div>
        </div>
    )
}


const GenderCustom = row => {
    console.log(row)
    return (
        <div style={{
            backgroundColor:
                row.gender.toLowerCase() === 'male' ? '#dcede5' : '#e9e3f6',
            color: row.gender.toLowerCase() === 'female' ? '#6f42c1' : '#51a47d',
            textAlign: 'center',
            fontWeight: '900',
            fontSize: '0.9em',
            width: "50px",
            padding: "2px 0",
            borderRadius: '8%',
        }}>
            {row.gender}
        </div>
    )
}

function FormData() {
    const [dateRange, setDateRange] = useState([moment().toDate(), moment().toDate()]);
    const [startDate, endDate] = dateRange;
    const authToken = localStorage.getItem("token");
    const [items, setItems] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pages, setPages] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { id, name } = useParams();

    const [from, setFrom] = React.useState(new Date());
    const [fromDate, setFromDate] = React.useState(
        date.toISOString().split("T")[0]
    );
    const [to, setTo] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(date.toISOString().split("T")[0]);

    const [dispDate, setDispDate] = React.useState({
        from: moment(date).format("MMM Do YY"),
        to: moment(date).format("MMM Do YY"),
    });
    const [centres, setCentres] = React.useState([]);
    const [selectedCentre, setSelectedCentre] = React.useState(null);
    const [conversionStatus, setConversionStatus] = React.useState([
        { value: null, label: "All" },
        { value: 1, label: "Converted" },
        { value: 0, label: "Not Converted" },
    ]);
    const [selectedConversionStatus, setSelectedConversionStatus] = React.useState(null);

    const styles = {
        control: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),
        menu: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),

    };

    const LocationCustom = (row) => (
        <div style={{ textAlign: "center" }}>
            {row.latitude && row.longitude ? (
                <a
                    href={`http://maps.google.com/maps?q=${row.latitude},${row.longitude}`}
                    target="_blank"
                >
                    <div style={{
                        color: '#4a4893',
                        textAlign: 'center',
                        fontWeight: '900',
                        fontSize: '1em',
                        width: "50px",
                        borderRadius: '8%',
                        margin: 'auto',
                        cursor: 'pointer',
                        transition: '0.4s',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: "3px 0"
                    }}>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <p style={{
                            margin: '0',
                            padding: '0',
                            paddingLeft: '5px',
                        }}>View</p>
                    </div>
                </a>
            ) : (
                <Button
                    color="primary"
                    variant="contained"
                    style={{ fontSize: "0.6em", backgroundColor: "#e53935" }}
                    startIcon={
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            style={{ fontSize: "10px" }}
                        />
                    }
                ></Button>
            )}
        </div>
    );

    const columns = [
        {
            name: "Date",
            selector: (row) => row.date,
            sortable: false,
            center: 1,
        },
        {
            name: "Time",
            selector: (row) => row.time,
            sortable: false,
            center: 1,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            center: 1,
        },
        {
            name: "Gender",
            selector: (row) => <GenderCustom {...row} />,
            sortable: false,
            center: 1,
            style: {
                textTransform: "capitalize",
            },
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: false,
            center: 1,
        },
        {
            name: "Age",
            selector: (row) => row.age,
            sortable: true,
            center: 1,
        },
        {
            name: "Location",
            selector: (row) => row.location,
            sortable: false,
            center: 1,
            cell: (row) => <LocationCustom {...row} />,
        },
        {
            name: "Village",
            selector: (row) => row.village,
            sortable: false,
            center: 1,
        },
        // {
        //     name: "Distance",
        //     selector: (row) => row.distance,
        //     center: 1,
        // },
        {
            cell: (row) => <Example row={row} />,
            center: 1,
            name: "Comments",
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize: '1em',
                backgroundColor: '#4a4893',
                color: '#fff',
                padding: '10px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9em',
            },
        },
        table: {
            style: {},
        },
        tableWrapper: {
            style: {
                display: 'table',
            },
        },
        responsiveWrapper: {
            style: {},
        },
        pagination: {
            style: {
                color: '#000',
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: '#fff',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#000',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#000',
                fill: '#000',
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#000',
                    fill: '#000',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#000',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#fff',
                },
            },
        },
    };

    const handleClick = async () => {
        if (dateRange[0] && dateRange[1]) {
            setIsLoading(true);
            setItems([]);
            const url = apiClient.Urls.syncedData;
            try {
                const response = await apiClient.post(url, {
                    authToken: authToken,
                    user_id: id,
                    page: 1,
                    start_date: dateRange[0].toISOString().split("T")[0],
                    end_date: dateRange[1].toISOString().split("T")[0],
                    type: "dashboard",
                    selectedCentre: selectedCentre,
                    conversionStatus: selectedConversionStatus
                });

                if (response?.data.length) {
                    let i = 0;
                    const mappedData = [];
                    var nextPage = 2;
                    do {
                        if (i == 0) {
                            const data = response?.data;
                            data.map((item) => {

                                const temp = {
                                    date: moment(item?.CreatedDate).format("LL"),
                                    time: moment(item?.CreatedDate).format("LT"),
                                    name: item?.Name,
                                    age: item?.Age,
                                    gender: item?.Gender,
                                    latitude: item.latitude,
                                    longitude: item.longitude,
                                    location:
                                        item.user.outreach_location_logs[0].latitude[0] +
                                        "_" +
                                        item.user.outreach_location_logs[0].longitude,
                                    village: item?.village.Name,
                                    phone: item?.Phone,
                                    comment: item?.Comments,
                                };
                                mappedData.push(temp);
                            });
                        } else {
                            const response = await apiClient.post(url, {
                                authToken: authToken,
                                user_id: id,
                                page: nextPage,
                                start_date: dateRange[0].toISOString().split("T")[0],
                                end_date: dateRange[1].toISOString().split("T")[0],
                                type: "dashboard",
                                selectedCentre: selectedCentre,
                                conversionStatus: selectedConversionStatus
                            });
                            const data = response?.data;
                            data.map((item) => {
                                const temp = {
                                    date: moment(item?.CreatedDate).format("LL"),
                                    time: moment(item?.CreatedDate).format("LT"),
                                    name: item?.Name,
                                    age: item?.Age,
                                    gender: item?.Gender,
                                    latitude: item?.latitude,
                                    longitude: item?.longitude,
                                    location: item?.latitude + "_" + item.longitude,
                                    village: item?.village.Name,
                                    phone: item?.Phone,
                                };
                                mappedData.push(temp);
                            });
                            nextPage++;
                        }
                        i++;
                    } while (
                        i <= response?.total_pages &&
                        nextPage <= response?.total_pages
                    );
                    setItems(mappedData);

                    toast.success("Records fetched", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true,
                    });

                } else {
                    toast.info("No records found", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                console.log(e);
            }
        } else {
            toast.info("Select both dates", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    };

    const getOutreachCentres = async () => {
        try {
            const response = await apiClient.post(
                apiClient.Urls.outreachCheckInCentre,
                {
                    authToken: authToken,
                    user_id: id,
                }
            );

            if (response.success) {
                const temp = [{
                    value: null,
                    label: "All"
                }];
                response?.data.map((item) => {
                    const obj = {
                        value: item.value,
                        label: item.label
                    }
                    temp.push(obj);
                })
                setCentres(temp);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        handleClick();
        getOutreachCentres();
    }, []);

    return (
        <div style={{ padding: "0 20px" }}>
            <Breadcrumb>
                <BreadcrumbItem active>{`Submitted Forms Data`}</BreadcrumbItem>
                <BreadcrumbItem active>{`${name}`}</BreadcrumbItem>
                <BreadcrumbItem
                    active
                >{`${moment(dateRange[0]).format("MMM Do YY")} to ${moment(dateRange[1]).format("MMM Do YY")}`}</BreadcrumbItem>
            </Breadcrumb>
            <div className="card">
                <DataTable
                    columns={columns}
                    data={items}
                    subHeader
                    progressPending={isLoading}
                    progressComponent={<Rings
                        height="120"
                        width="120"
                        color="#4fa94d"
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                    />
                    }
                    subHeaderComponent={
                        <SubHeaderComponent from={from} to={to} setTo={setTo} setFrom={setFrom} centres={centres} setCentres={setCentres} selectedCentre={selectedCentre} setSelectedCentre={setSelectedCentre} handleClick={handleClick} dispDate={dispDate} setDispDate={setDispDate} styles={styles} conversionStatus={conversionStatus} setSelectedConversionStatus={setSelectedConversionStatus} toDate={toDate} fromDate={fromDate}
                            dateRange={dateRange} setDateRange={setDateRange} startDate={startDate} endDate={endDate}
                            setFromDate={setFromDate} setToDate={setToDate} selectedConversionStatus={selectedConversionStatus}
                        />
                    }
                    pagination
                    paginationServer
                    dense
                    customStyles={customStyles}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        showFirstButton: false,
                        showLastButton: false,
                    }}
                />
            </div>
            <ToastContainer />
        </div>
    );
}

export default FormData;
