import React from 'react'
import DataTable from 'react-data-table-component';
import "./Home.css"
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import apiClient from '../../apiClient';
import "react-data-table-component-extensions/dist/index.css";
import { sortemployees } from "../../util"
const date = new Date().getFullYear();

function Main() {
    const [dropDownData, setDropDownData] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const authToken = localStorage.getItem("token");
    const someVar = undefined;
    const [search, setSearch] = React.useState(someVar || '');
    const [filteredNames, setFilteredNames] = React.useState([]);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            center: 1,
            width: "25%"
        },
        {
            name: 'Division',
            selector: row => row.address,
            sortable: true,
            center: 1
        },
        {
            name: 'Achieved',
            selector: row => row.achieved,
            sortable: true,
            center: 1
        },
        {
            name: 'Target',
            selector: row => row.target,
            sortable: true,
            center: 1
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize: '1.1em',
                // backgroundColor: "#4a4893",
                padding: '15px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.9em',
                width: '120%',
            },
        },
    };

    const styles = {
        control: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),
        menu: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),

    };
    const getEmployeeData = async () => {
        try {
            const res = await apiClient.post(`${apiClient.Urls.employeeData}`, {
                authToken: authToken,
            })
            const data = res["data"];
            const employee_data = data.map((item) => {
                return {
                    id: item.id,
                    name: item.username,
                    address: item.chw?.Village,
                    achieved: item.outreachapp_data.length,
                    target: 10
                }
            })
            console.log(employee_data);
            employee_data.sort(sortemployees);
            setDropDownData(employee_data);
            setFilteredNames(employee_data);
        }
        catch (e) {
            console.log("err---------------" + e);
        }
    }
    async function getCenter() {
        try {
            const response = await apiClient.post(`${apiClient.Urls.village}`, {
                authToken: authToken
            })
            if (response.length > 0) {
                const data = response.map((item, index) => {
                    return {
                        label: item.Name,
                        value: item.VillageId
                    }
                })
                setOptions(data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        getEmployeeData()
        getCenter()
    }, [])

    React.useEffect(() => {
        const res = dropDownData.filter((data) => {
            return data.name.toLowerCase().match(search.toLowerCase());
        })

        setFilteredNames(res)
    }, [search])

    return (
        <>
            <div style={{ padding: "0 20px" }}>
                <Breadcrumb>
                    <BreadcrumbItem active>Outreach Executives List</BreadcrumbItem>
                </Breadcrumb>

                {
                    filteredNames.length < 0 ? <h1>Loading</h1> : <div className='card'>
                        <DataTable
                            columns={columns}
                            data={filteredNames}
                            subHeader
                            subHeaderComponent={<input type="text" placeholder='Search ORE' className="w-25 form-control" value={search} onChange={(e) => setSearch(e.target.value)} />}
                            pagination
                            dense
                            customStyles={customStyles}
                            highlightOnHover
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                        />
                    </div>
                }
            </div >
            <div class="footer">
                <p>Copyright © {date} Karma Primary Healthcare</p>
            </div>
        </>
    )
}

export default Main;