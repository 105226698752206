import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDatabase,
    faPersonRunning,
    faLocationDot,
    faBuildingCircleCheck,
    faFileExport
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

const SideBar = ({ isOpen, toggle }) => (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
        <div className="sidebar-header">
            <span color="info" onClick={toggle} style={{ color: "#fff" }}>
                &times;
            </span>
            <img src="https://karmahealthcare.in/assets/img/karma_logo.png" alt="Karma Logo" style={{ width: "60%", }} />
            {/* button when screen size is small */}


        </div>
        <div className="side-menu">
            <Nav vertical className="list-unstyled pb-5" >
                <NavItem>
                    <NavLink tag={Link} to={"/dashboard"} className="sidebar-menu">
                        <FontAwesomeIcon icon={faPersonRunning} style={{ fontSize: "18px", marginRight: '10px', color: "#4c4c4c" }} />
                        <span style={{ fontSize: "1.2em" }}>Outreach Executives Activity
                        </span>
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink tag={Link} to={"/dashboard/records"} className="sidebar-menu">
                        <FontAwesomeIcon icon={faDatabase} style={{ fontSize: "18px", marginRight: '10px', color: "#4c4c4c" }} />
                        <span style={{ fontSize: "1.2em" }}>Outreach Executives List
                        </span>
                    </NavLink>
                </NavItem> */}
                <NavItem >
                    <NavLink tag={Link} to={"/dashboard/track"} className="sidebar-menu">
                        <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: "18px", marginRight: '10px', color: "#4c4c4c" }} />
                        <span style={{ fontSize: "1.2em" }}>Track Activity
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink tag={Link} to={"/dashboard/checkin"} className="sidebar-menu">
                        <FontAwesomeIcon icon={faBuildingCircleCheck} style={{ fontSize: "18px", marginRight: '10px', color: "#4c4c4c" }} />
                        <span style={{ fontSize: "1.2em" }}>Attendance Records
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink tag={Link} to={"/dashboard/export"} className="sidebar-menu">
                        <FontAwesomeIcon icon={faFileExport} style={{ fontSize: "18px", marginRight: '10px', color: "#4c4c4c" }} />
                        <span style={{ fontSize: "1.2em" }}>Export Records
                        </span>
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    </div>
);

export default SideBar;
