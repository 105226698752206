import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import {
    Navbar,
    Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css'; // import first
import { ToastContainer, toast } from 'react-toastify'; // then this

const Topbar = ({ toggleSidebar }) => {
    const [topbarIsOpen, setTopbarOpen] = useState(true);
    // const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const logout = () => {
        toast.info("Logged out successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
        })
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
        window.location.href = "/login"
    }
    return (
        <>
            <Navbar
                style={{
                    backgroundColor: "#fff", padding: "8px", minHeight: "60px",
                    border: '1px solid #E0E0E0',
                }}
                light
                className="navbar mb-3"
                expand="md"
            >
                <Button onClick={toggleSidebar} style={{
                    padding: "0px 5px", color: "none", borderColor: "none",
                    backgroundColor: "none", border: "none"

                }} color="none">
                    <FontAwesomeIcon icon={faBars} style={{ fontSize: "22px", color: "#757575" }} />
                </Button>
                <Button style={{
                    padding: "0px 5px", color: "none", borderColor: "none",

                    backgroundColor: "none", border: "none",
                    // on hover background color
                    // backgroundColor: "#E0E0E0",

                }} color="none" onClick={
                    logout
                }>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ fontSize: "16px", color: "black" }} />
                    <span style={{ fontSize: "16px", color: "black", paddingLeft: "10px" }}>
                        Logout
                    </span>
                </Button>
            </Navbar>
            <ToastContainer />
        </>
    );
};

export default Topbar;
