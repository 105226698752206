const initialState = {
    authToken: null,
    phoneNumber: null,
    otp: null,
    isLoggedIn: false,
    latitude: null,
    longitude: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SEND_OTP":
            // console.log(action.payload);
            return {
                ...state,
                phoneNumber: action.payload,
            }
        case "VERIFY_OTP":
            // console.log(action.payload);
            return {
                ...state,
                isLoggedIn: action.payload.success,
                authToken: action.payload.token,
            }
        case "EDIT_PHONE":
            return {
                ...state,
                phoneNumber: null,
            }
        case "LOGIN":
            return {
                ...state,
                phoneNumber: action.payload,
                isLoggedIn: true,
                authToken: action.payload,
            }
        case "SET_LOCATION":
            return {
                ...state,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
            }
        case "LOGOUT":
            return {
                ...state,
                phoneNumber: null,
                isLoggedIn: false,
                authToken: null,
            }
        default:
            return state;
    }
}