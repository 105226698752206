import React, { Component, useEffect, useState } from 'react';
import Map from './Map';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import Select from 'react-select'
import "./Home.css"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import apiClient from '../../apiClient';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const googleMapsApiKey = 'AIzaSyDurZQBXjtSzKeieXwtFeGe-jhZu-HEGQU';
const year = new Date().getFullYear();
let yourDate = new Date()
const Track2 = (props) => {

    const [state, setState] = useState({
        options: [],
        startDate: new Date(),
        authToken: localStorage.getItem('token'),
        value: null,
        date: yourDate.toISOString().split('T')[0],
        pathCoordinates: [],
        directions: null,
        distance: null,
        origin: {
            lat: 0,
            lng: 0
        }
    })

    const [startDate, setStartDate] = useState(new Date());

    const sortemployees = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    function handleDateChange(currentdate) {
        setStartDate(currentdate);
        const d = new Date(currentdate).toLocaleDateString('fr-FR');
        const newD = d.split("/");
        const newDate = newD[2] + "-" + newD[1] + "-" + newD[0];
        setState({
            ...state,
            date: newDate,
        })
    }

    function handleDropdown(choice) {
        const val = choice.value;
        setState({
            ...state,
            value: val
        })
    }

    const getEmployeeData = async () => {
        try {
            const res = await apiClient.post(`${apiClient.Urls.employeeData}`, {
                authToken: state.authToken,
            })
            if (res.success) {
                const data = res["data"];
                const employee_data = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.username,
                    }
                })
                employee_data.sort(sortemployees);
                setState({
                    ...state,
                    options: employee_data
                })
            } else {
                alert("wait")
            }

        }
        catch (e) {
            console.log("err---------------" + e);
        }
    }

    async function getLocation() {
        if (state.date || state.value) {
            try {
                const response = await apiClient.post(`${apiClient.Urls.locationData}`, {
                    authToken: state.authToken,
                    user_id: state.value,
                    date: state.date
                })
                if (response["message"] === "jwt expired") {
                    toast.error("Token expired", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                } else {
                    if (response["success"]) {
                        const location = response["data"];
                        if (location.length > 0) {
                            const tempData = {
                                lat: parseFloat(location[0].user?.outreach_location_logs[0].latitude),
                                lng: parseFloat(location[0].user?.outreach_location_logs[0].longitude)
                            }
                            setState({
                                ...state,
                                origin: tempData
                            })
                            const empLocation = location.map((cord, id) => {
                                const latitude = parseFloat(cord.latitude);
                                const longitude = parseFloat(cord.longitude);
                                return {
                                    latitude: latitude,
                                    longitude: longitude
                                }
                            })
                            setState({
                                ...state,
                                pathCoordinates: empLocation
                            })

                            console.log(state.pathCoordinates)

                            toast.success("Data fetched successfully", {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                hideProgressBar: true
                            })
                        }

                        else {
                            toast.info("No records found", {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                hideProgressBar: true
                            })
                        }
                    }
                }

            } catch (e) {
                console.log(e);
                toast.error("Please check your internet connection", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: true
                })
            }
        } else {
            toast.info("Please select both", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    const styles = {
        control: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),
        menu: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),
    };
    const { options } = state;

    const {
        loadingElement,
        containerElement,
        mapElement,
        defaultCenter,
        defaultZoom,
    } = props;

    useEffect(() => {
        getEmployeeData();
    }, [])

    useEffect(() => {

    }, [state.pathCoordinates])
    return (
        <div style={{ padding: "0 20px" }}>
            <Breadcrumb>
                <BreadcrumbItem active>Track Activity</BreadcrumbItem>
            </Breadcrumb>
            <div className='header'>
                <div className='card filters'>
                    <div style={{ marginRight: "20px" }}>
                        <Select options={options} styles={styles} placeholder="Select Employee" className="select" onChange={(choice) => handleDropdown(choice)} />
                    </div>
                    <div>
                        <DatePicker onChange={(date) => {
                            handleDateChange(date)
                        }}
                            placeholderText="Select date"
                            maxDate={new Date()}
                            className="w-100 "
                            value={state.date}
                            selected={startDate}
                        />
                    </div>
                    <div style={{ justifyContent: "flex-end", marginLeft: "20px" }}>
                        <Button color="primary" className="btn-primary" onClick={() => {
                            getLocation()
                        }}>Search</Button>
                    </div>
                </div>
            </div>
            {
                state.pathCoordinates.length > 0 && <Map
                    googleMapURL={
                        'https://maps.googleapis.com/maps/api/js?key=' +
                        'AIzaSyB9ez9ADkm0tJoJmZzY0KmAGxiFIDomoJU&v=3' +
                        '&libraries=geometry,drawing,places'
                    }
                    markers={state.pathCoordinates}
                    loadingElement={loadingElement || <div style={{ height: `100%` }} />}
                    containerElement={containerElement || <div style={{ height: '80vh' }} />}
                    mapElement={mapElement || <div style={{ height: `100%` }} />}
                    defaultCenter={defaultCenter || { lat: 25.798939, lng: -80.291409 }}
                    defaultZoom={defaultZoom || 11}

                />
            }
            <ToastContainer />
            <div className="footer">
                <p>Copyright © {year} Karma Primary Healthcare</p>
            </div>
        </div>
    );
};

export default Track2