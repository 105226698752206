export const baseUrl = 'https://api.karmaprimaryhealthcare.in/';
// export const baseUrl = 'http://127.0.0.1:3000/'
const apiClient = {
    Urls: {
        login: 'api/auth/login',
        verifyOtp: 'api/auth/verify',
        village: 'api/home/getVillage',
        userDetails: 'api/profile/fetch',
        submit: 'api/survey/save',
        employeeData: 'api/survey/getEmployeeWiseData',
        syncedData: 'api/survey/syncedData',
        locationData: 'api/survey/getLocationData',
        checkIn: "api/survey/checkin_data",
        userData: "api/survey/export",
        leadScore: "api/survey/getEmployeeActivity",
        outreachCheckInCentre: "api/survey/outreachCheckInCentre",
    },

    make: function (url, method, params) {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }

        if (params?.authToken) {
            headers['Authorization'] = `Bearer ${params?.authToken}`
        }

        return fetch(baseUrl + url, {
            method,
            headers,
            body: JSON.stringify(params),
        }).then(
            response => response.json()
        ).catch(error => {
        });
    },

    post: function (url, params) {
        return this.make(url, 'POST', params);
    },

    get: function (url, params) {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
        if (params.authToken) {
            headers['Authorization'] = `Bearer ${params.authToken}`
        }

        return fetch(baseUrl + url, {
            method: 'GET',
            headers,
        }).then(response => response.json())
            .catch(error => {
                return {
                    success: false,
                    message: error?.message || error || 'Something went wrong!',
                };
            })
    },
};

export default apiClient;
