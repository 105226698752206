import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import DatePicker from 'react-datepicker';
import { useEffect, useState } from "react";
import moment from "moment";
import Select from 'react-select'
import apiClient from "../../apiClient";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from 'xlsx';
const date = new Date();

const ExportRecords = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [from, setFrom] = useState(new Date());
    const [fromDate, setFromDate] = useState(date.toISOString().split('T')[0]);
    const [to, setTo] = useState(new Date());
    const [toDate, setToDate] = useState(date.toISOString().split('T')[0]);

    const authToken = localStorage.getItem('token');
    const [options, setOptions] = useState([]);

    const [value, setValue] = useState('all');
    const [data, setData] = useState([]);

    function sortemployees(a, b) {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    const getEmployeeData = async () => {
        try {
            const res = await apiClient.post(`${apiClient.Urls.employeeData}`, {
                authToken: authToken,
            })
            if (res.success) {
                const data = res["data"];
                const employee_data = data.map((item) => {
                    return {
                        value: item.id,
                        label: item.username,
                    }
                })
                employee_data.sort(sortemployees);
                console.log(employee_data)
                setOptions(employee_data);
            } else {
                alert("wait")
            }

        }
        catch (e) {
            console.log("err---------------" + e);
        }
    }

    const getData = async () => {
        try {
            const res = await apiClient.post(`${apiClient.Urls.userData}`, {
                authToken, authToken,
                employee_id: value,
                start_date: fromDate,
                end_date: toDate
            })
            console.log(res)
            if (res.status) {
                if (res.data.length > 0) {
                    toast.success("Records found", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    const worksheet = XLSX.utils.json_to_sheet(res.data);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, Math.random().toString(36).substring(2, 15) + Math.random().toString(23).substring(2, 5));
                    XLSX.writeFile(workbook, "DataSheet.xlsx");
                } else {
                    toast.info("No records found", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }
            } else {
                toast.info("Select all fields", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: true
                })
            }
        } catch (e) {
            console.log(e)
            toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    useEffect(() => {
        getEmployeeData();
    }, [])

    const styles = {
        control: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),
        menu: base => ({
            ...base,
            fontFamily: "sans-serif",
            fontSize: "10px",
        }),

    };

    const handleDropdown = (choice) => {
        const val = choice.value;
        const stringVal = val.toString();
        setValue(stringVal);
    }

    return (
        <div style={{ padding: "0 20px" }}>
            <Breadcrumb>
                <BreadcrumbItem active>{`Export Form Data`}</BreadcrumbItem>
            </Breadcrumb>

            <div style={{ display: "flex", flexDirection: "row", height: "2vw", justifyContent: "space-around", alignItems: "center", textAlign: "center", float: "right" }} className="w-100 ">
                <div>
                    <Select options={options} styles={styles} placeholder="Select Employee" className="select" onChange={(choice) => handleDropdown(choice)} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginRight: "10px", justifyContent: "space-between", alignItems: "center" }}>
                    <p>From</p>
                    <div>
                        <DatePicker dateFormat="dd/MM/yyyy" selected={from}
                            onChange={(date) => {
                                setFrom(date);
                                const temp = date.toISOString().split('T')[0];
                                setFromDate(temp);
                                setDispDate({
                                    ...dispDate,
                                    from: moment(date).format("MMM Do YY")
                                })
                            }}
                            maxDate={new Date()}
                            className="w-100 form-control pr-10" />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <p>To </p>
                    <DatePicker dateFormat="dd/MM/yyyy"
                        selected={to}
                        onChange={(date) => {
                            setTo(date)
                            const temp = date.toISOString().split('T')[0];
                            setToDate(temp)
                            setDispDate({
                                ...dispDate,
                                to: moment(date).format("MMM Do YY")
                            })
                        }}
                        maxDate={new Date()}
                        className="w-100 form-control mr-10" />

                    <div>
                        <Button color="primary" onClick={getData}>
                            Download
                        </Button>
                    </div>
                </div>

                <div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default ExportRecords;