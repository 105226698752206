import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))
const RootReducers = combineReducers({
    reducers,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'reducers'
    ],
};

const persistedReducer = persistReducer(persistConfig, RootReducers);
export const store = createStore(persistedReducer, composedEnhancer);
export const persistor = persistStore(store);
