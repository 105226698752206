/* global google */
import React from "react";
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    DirectionsRenderer
} from "react-google-maps";

class MapDirectionsRenderer extends React.Component {
    state = {
        directions: null,
        error: null
    };

    drawRoute() {
        const { places, travelMode } = this.props;

        console.log(places)
        var lngs = places.map(function (station) { return station.longitude; });
        var lats = places.map(function (station) { return station.latitude; });

        for (var i = 0; i < places.length; i++) {
            new google.maps.Marker({
                position: places[i],
            });
        }

        for (var i = 0, parts = [], max = 25 - 1; i < places.length; i = i + max)
            parts.push(places.slice(i, i + max + 1));


        const waypoints = places.map(p => ({
            location: { lat: p.latitude, lng: p.longitude },
            stopover: true
        }))
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: travelMode,
                waypoints: waypoints
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result
                    });
                } else {
                    this.setState({ error: result });
                }
            },
        );
    }

    componentDidMount() {
        this.drawRoute();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.places !== this.props.places) {
            this.drawRoute()
        }
    }


    render() {
        if (this.state.error) {
            return <h1>{this.state.error}</h1>;
        }
        return (this.state.directions && <DirectionsRenderer directions={this.state.directions} />)
    }
}

const Map = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultCenter={props.defaultCenter}
            defaultZoom={props.defaultZoom}
        >
            <MapDirectionsRenderer
                places={props.markers}
                travelMode={google.maps.TravelMode.DRIVING}
            />
        </GoogleMap>
    ))
);

export default Map;
