import React, { useState } from 'react'
import DataTable, { Export } from 'react-data-table-component';
import "./Home.css"
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import LaunchIcon from '@mui/icons-material/Launch';

import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import apiClient from '../../apiClient';
import { sortemployees } from "../../util"
import { CSVLink } from 'react-csv';
import { Rings } from 'react-loader-spinner';
const date = new Date().getFullYear();

function Records() {
    const [dropDownData, setDropDownData] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const authToken = localStorage.getItem("token")

    const someVar = undefined;
    const [search, setSearch] = React.useState(someVar || '');
    const [filteredNames, setFilteredNames] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const [totalData, setTotalData] = React.useState({
        totalExecutives: 0,
        totalFormsFilled: 0,
        totalFormsConverted: 0,
        totalConversionPercentage: 0
    });

    const AchievedCustom = row => <div style={{ textAlign: "center" }}>
        <Link to={`/dashboard/form-data/${row.id}/${row.name}`} >
            <Button variant="contained" style={{ fontSize: "0.7em", backgroundColor: "green", color: "white" }} endIcon={<LaunchIcon style={{ fontSize: "12px", color: "white" }} />}>
                View {row.achieved}
            </Button>
        </Link>
    </div>

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            center: 1,
            sortable: true,
        },
        {
            name: 'Division',
            selector: row => row.address,
            center: 1,
            sortable: true,
        },
        {
            name: "Total Forms",
            selector: row => row.leadScore,
            sortable: true,
            center: 1,
        },
        {
            name: "Conversion (in %)",
            selector: row => row.conversion,
            sortable: true,
            center: 1,
        },
        {
            name: "Forms Converted",
            selector: row => row.leadActive,
            sortable: true,
            center: 1,
        },
        {
            name: "Forms Submitted",
            selector: row => row.achieved,
            sortable: true,
            center: 1,
            cell: row => <AchievedCustom {...row} />,
        },
    ];

    async function getCenter() {
        try {
            const response = await apiClient.post(`${apiClient.Urls.village}`, {
                authToken: authToken
            })
            if (response.length > 0) {
                const data = response.map((item, index) => {
                    return {
                        label: item.Name,
                        value: item.VillageId
                    }
                })
                setOptions(data)
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function getEmployeeData() {
        try {
            setLoading(true);
            const res = await apiClient.post(`${apiClient.Urls.leadScore}`, {
                authToken: authToken,
            })

            if (res?.expiredAt) {
                localStorage.setItem("token", null);
            }
            else {

                const data = res["data"];
                const totalForms = res["totalForms"];
                const totalFormsConverted = res["totalConversion"];
                const totalConversionPercentage = res["totalConversionPercentage"];
                setTotalData({
                    totalExecutives: data.length,
                    totalFormsFilled: totalForms,
                    totalFormsConverted: totalFormsConverted,
                    totalConversionPercentage: totalConversionPercentage
                })
                const employee_data = data.map((item) => {
                    return {
                        id: item.id,
                        name: item.username,
                        address: item?.Division ? item?.Division : "Not known",
                        village: item?.Centre ? item?.Centre : "Not known",
                        leadScore: item?.outreachapp_data.length,
                        leadActive: item?.ConversionCount ? item?.ConversionCount : 0,
                        conversion: item?.Conversion ? item?.Conversion : "Not known"
                    }

                })
                employee_data.sort(sortemployees);

                setDropDownData(employee_data);
                setFilteredNames(employee_data)
                setLoading(false);
            }
        }
        catch (e) {
            console.log("err---------------" + e);
        }
    }

    React.useEffect(() => {
        getEmployeeData()
        getCenter()
    }, [])

    React.useEffect(() => {
        const res = dropDownData.filter((data) => {
            return data.name.toLowerCase().match(search.toLowerCase());
        })

        setFilteredNames(res)
    }, [search])

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                fontSize: '0.9em',
                backgroundColor: '#4a4893',
                color: '#fff',
                padding: '10px',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                fontSize: '0.85em',
            },
        },
        table: {
            style: {},
        },
        tableWrapper: {
            style: {
                display: 'table',
            },
        },
        responsiveWrapper: {
            style: {},
        },
        pagination: {
            style: {
                color: '#000',
                fontSize: '13px',
                minHeight: '56px',
                backgroundColor: '#fff',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#000',
            },
            pageButtonsStyle: {
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                padding: '8px',
                margin: 'px',
                cursor: 'pointer',
                transition: '0.4s',
                color: '#000',
                fill: '#000',
                backgroundColor: 'transparent',
                '&:disabled': {
                    cursor: 'unset',
                    color: '#000',
                    fill: '#000',
                },
                '&:hover:not(:disabled)': {
                    backgroundColor: '#000',
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: '#fff',
                },
            },
        },
    };
    return (
        <>
            <div style={{ padding: "0 20px" }}>
                <Breadcrumb>
                    <BreadcrumbItem active>Outreach Executives Activity</BreadcrumbItem>
                </Breadcrumb>

                {
                    loading ?
                        <div style={{
                            display: "flex", alignItems: "center", justifyContent: "center"
                        }}><Rings
                                height="120"
                                width="120"
                                color="#4fa94d"
                                radius="6"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="rings-loading"
                            /> </div> : filteredNames.length < 0 ? <h1>Loading...</h1> :
                            <>
                                <div className='row' style={{
                                    marginTop: "20px",
                                    marginBottom: "20px"

                                }}>
                                    {/* three responsive boxes */}
                                    <div className="col-md-3 col-sm-12">
                                        <div className="card" >
                                            <div className="card-body" style={{
                                                backgroundColor: "#f56852", color: "white"
                                            }}>
                                                <h6 className="card-title" >Total Executives</h6>
                                                <h2 className="card-text" >{totalData.totalExecutives}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="card" >
                                            <div className="card-body" style={{
                                                backgroundColor: "#f39c11", color: "white"
                                            }}>
                                                <h6 className="card-title">Total Forms Filled</h6>
                                                <h2 className="card-text">{totalData.totalFormsFilled}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="card">
                                            <div className="card-body" style={{
                                                backgroundColor: "#00c0ed", color: "white"
                                            }}>
                                                <h6 className="card-title">Total Forms Converted</h6>
                                                <h2 className="card-text">{totalData.totalFormsConverted}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12" style={{
                                        borderRadius: "10px",
                                    }}>
                                        <div className="card">
                                            <div className="card-body" style={{
                                                backgroundColor: "#00a558", color: "white"
                                            }}>
                                                <h6 className="card-title">Conversion Percentage</h6>
                                                <h2 className="card-text">{totalData.totalConversionPercentage}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card'>

                                    <DataTable
                                        columns={columns}
                                        data={filteredNames}
                                        pagination
                                        dense
                                        customStyles={customStyles}
                                        Clicked
                                        highlightOnHover
                                        persistTableHead
                                        subHeader
                                        theme='solarized'
                                        subHeaderComponent={
                                            <div style={{ textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                                <input type="text" placeholder='Name' className="w-50 form-control" value={search} onChange={(e) => setSearch(e.target.value)} style={{
                                                    borderRadius: "0px",
                                                    height: "30px",
                                                    fontSize: "0.8em",
                                                    marginRight: "10px"

                                                }} />
                                            </div>
                                        }
                                        paginationComponentOptions={{ noRowsPerPage: true }}
                                    />
                                </div>
                            </>

                }
            </div>
            <div class="footer">
                <p>Copyright © {date} Karma Primary Healthcare</p>
            </div>
        </>
    )
}

export default Records