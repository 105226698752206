import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";

import Topbar from "./Topbar";
import Main from "../../pages/Home/Main";
import Records from "../../pages/Home/Records";
import FormData from "../../pages/Home/FormData";
import CheckIn from "../../pages/Home/CheckIn";
import ExportRecords from "../../pages/Home/ExportRecords";
import Track from "../../pages/Home/Track";

const Content = ({ sidebarIsOpen, toggleSidebar }) => (
    <Container style={{ padding: 0 }}
        className={classNames("content", { "is-open": sidebarIsOpen })}
        fluid
    >
        <Topbar toggleSidebar={toggleSidebar} />
        <Routes>
            <Route exact path="/dashboard" element={<Records />} />
            <Route exact path="/dashboard/records" element={<Main />} />
            <Route exact path="/dashboard/track" element={<Track />} />
            <Route path="/dashboard/form-data/:id/:name" element={<FormData />} />
            <Route path="/dashboard/checkin" element={<CheckIn />} />
            <Route path="/dashboard/export" element={<ExportRecords />} />
        </Routes>
    </Container>
);

export default Content;
