import * as React from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient';
import 'react-toastify/dist/ReactToastify.css'; // import first
import { ToastContainer, toast } from 'react-toastify'; // then this
import image from "./background.png"
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}

            Karma Primary Healthcare Pvt Ltd {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme()


export default function OtpPage() {

    const dispatch = useDispatch();
    const [otp, setOtp] = React.useState("");

    const phoneNumber = useSelector((state) => state.reducers.phoneNumber);

    let navigate = useNavigate();

    const submit = () => async (getState) => {
        try {
            const url = apiClient.Urls.verifyOtp;
            const response = await apiClient.post(url, {
                Mobile: phoneNumber,
                OTP: otp,
            });
            if (response["success"]) {

                const res = await apiClient.post(apiClient.Urls.userDetails, {
                    authToken: response.token
                });
                if (res.data.type === "manager") {
                    toast.success("Logged in successfully", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                    const token = response.token;
                    dispatch({ success: true, type: "VERIFY_OTP", payload: response })
                    localStorage.setItem("token", token);
                    navigate("/dashboard")
                } else {
                    toast.error("Not a valid user", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: true
                    })
                }
            } else {
            }
        } catch (e) {
            toast.error("Enter correct OTP", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: true
            })
        }
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={9}
                        style={{ backgroundImage: `url(${image})`, }}
                        sx={{

                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={3} component={Paper} elevation={9} square>
                        <Box
                            sx={{
                                my: 25,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src="https://karmahealthcare.in/assets/img/logo_new.png" alt="" style={{ width: '250px' }} />
                            <Typography component="h1" variant="h6" sx={{ mt: 3, color: '#636578', textAlign: "center" }}>
                                Welcome to Karma Primary Healthcare
                            </Typography>

                            <Typography component="h1" variant="body1" sx={{ mt: 1, color: 'rgba(76, 78, 100, 0.68)' }}>
                                Please enter 6 digits OTP
                            </Typography>

                            <Box component="form" noValidate sx={{ mt: 1, color: 'rgba(76, 78, 100, 0.68)' }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="OTP"
                                    autoComplete="off"
                                    autoFocus
                                    value={otp}
                                    onChange={
                                        (e) => {
                                            setOtp(e.target.value)
                                        }
                                    }
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    inputProps={
                                        {
                                            inputMode: "numeric",
                                            pattern: "[0-9]{3}[0-9]{2}[0-9]{3}",
                                            maxLength: 6
                                        }
                                    }
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        fontWeight: 500,
                                        backgroundColor: '#26c6f9',
                                    }}
                                    onClick={submit()}
                                >
                                    Verify & Continue
                                </Button>
                                <Copyright
                                    sx={{
                                        display: 'absolute',
                                        bottom: 5
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <ToastContainer />
        </>
    );
}
