import React, { useState } from "react";
import { Route, Routes, useHref } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login/Login";
import OtpPage from "./pages/Login/OtpPage";
import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from "redux-persist/integration/react";
import AppLogout from "./AppLogout";

const AuthStack = () => {
  React.useEffect(() => {
    document.title = "Outreach | Login"
  }, [])
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} title="Login" />
        <Route path="/otp" element={<OtpPage />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

const MainStack = () => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  React.useEffect(() => {
    document.title = "Outreach | Dashboard"
  }, [])

  window.addEventListener("resize", () => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  });

  return (
    <AppLogout>

      <div className="App wrapper">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
        <ToastContainer />
      </div>
    </AppLogout>
  );
};

const App = () => {
  const authToken = useSelector((state) => state.reducers.authToken);
  const phoneNumber = useSelector((state) => state.reducers.phoneNumber);
  React.useEffect(() => {
    const url = window.location.pathname;
    if (url === "/") {
      if (authToken) {
        window.location.href = "/dashboard"
      } else {
        window.location.href = "/login";
      }
    }
    if (url === "/otp") {
      if (!phoneNumber) {
        window.location.href = "/login";
      }
    }
    if (url.includes("/dashboard") && !authToken) {
      window.location.href = "/login";
    }
    if (authToken) {
      if (url === "/login" || url === "/otp") {
        window.location.href = "/dashboard";
      }
    }
  }, [])
  return (
    <div className="App">
      {
        authToken ? <MainStack /> : <AuthStack />
      }
      <ToastContainer />
    </div>
  )
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<><h1>Loading...</h1></>} >
        <App />
      </PersistGate>
      <ToastContainer />
    </Provider>
  )
}

export default AppWrapper;
